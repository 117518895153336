import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M2780 6179 c-270 -45 -407 -325 -339 -694 27 -143 111 -260 215 -298
108 -38 250 -17 305 47 33 38 42 33 34 -21 -12 -82 -49 -136 -136 -197 l-24
-17 39 -74 c22 -41 44 -75 49 -75 5 0 41 17 79 37 86 45 143 101 177 173 25
54 25 58 29 343 l4 287 -116 0 -116 0 0 -53 c0 -29 -5 -80 -11 -112 -39 -214
-201 -252 -261 -61 -16 51 -26 235 -17 316 10 85 22 121 56 168 62 83 179 84
283 2 l38 -30 51 64 c28 35 51 68 51 73 0 18 -56 62 -111 87 -94 43 -170 53
-279 35z"
          />
          <path
            d="M6550 6176 c-150 -34 -237 -126 -247 -260 -7 -86 23 -162 85 -217 26
-23 101 -67 167 -100 131 -63 155 -87 155 -154 0 -27 -8 -44 -31 -67 -29 -29
-33 -30 -97 -26 -48 4 -85 14 -132 37 -36 18 -69 36 -74 42 -6 5 -14 9 -18 9
-11 0 -78 -139 -78 -161 0 -19 107 -71 196 -93 93 -24 219 -21 291 6 66 26
138 91 175 159 19 37 23 59 23 139 0 70 -4 103 -17 126 -31 57 -92 105 -198
156 -58 28 -119 61 -136 74 -62 46 -69 121 -14 149 49 25 104 19 196 -24 l84
-40 41 81 c22 44 39 81 37 83 -2 1 -27 14 -55 29 -104 52 -256 75 -353 52z"
          />
          <path
            d="M2032 5673 l3 -497 115 2 c63 1 118 2 123 2 4 0 7 223 7 495 l0 495
-125 0 -125 0 2 -497z"
          />
          <path
            d="M3370 5675 l0 -495 120 0 120 0 -1 198 c-1 108 -4 241 -8 295 -3 53
-3 97 1 97 4 0 23 -28 44 -62 21 -35 107 -168 192 -295 l154 -233 104 0 104 0
0 495 0 495 -122 0 -121 0 6 -300 c3 -165 4 -300 3 -300 -2 0 -29 42 -60 93
-31 50 -72 115 -91 142 -20 28 -55 79 -78 115 -23 36 -69 106 -103 155 l-61
90 -101 3 -102 3 0 -496z"
          />
          <path d="M4380 5675 l0 -495 120 0 120 0 0 495 0 495 -120 0 -120 0 0 -495z" />
          <path
            d="M4720 6085 l0 -85 88 -1 c48 -1 101 -2 117 -3 l30 -1 3 -407 2 -408
120 0 120 0 2 408 3 407 102 1 c56 1 108 4 116 7 8 3 16 25 20 54 3 26 9 63
13 81 l7 32 -372 0 -371 0 0 -85z"
          />
          <path
            d="M5562 5674 l3 -496 305 1 c168 1 315 1 328 1 22 0 23 2 20 86 l-3 85
-199 2 -200 2 -8 30 c-4 17 -4 74 0 125 l7 95 145 5 144 5 7 35 c4 19 10 56
14 83 l6 47 -160 0 c-88 0 -162 3 -164 8 -3 4 -2 53 1 110 l5 102 193 0 194 0
0 85 0 85 -320 0 -320 0 2 -496z"
          />
          <path
            d="M7102 5808 c3 -356 4 -364 27 -423 17 -41 41 -76 77 -112 73 -70 139
-95 271 -101 179 -8 293 36 374 142 70 92 73 109 77 504 l3 352 -123 0 -123 0
0 -333 c0 -375 -4 -403 -72 -454 -63 -48 -181 -32 -231 32 -36 45 -42 113 -42
440 l0 315 -121 0 -120 0 3 -362z"
          />
          <path
            d="M8097 6163 c-7 -11 -7 -785 0 -895 l6 -88 193 0 c314 0 429 39 485
164 57 130 14 253 -111 318 -64 33 -71 43 -35 52 43 11 122 95 135 145 17 63
4 133 -33 189 -25 36 -48 55 -101 82 l-69 35 -232 3 c-128 2 -235 0 -238 -5z
m346 -154 c26 -7 44 -21 57 -42 36 -59 20 -170 -29 -196 -16 -9 -83 -16 -118
-12 -10 1 -13 33 -13 124 0 68 3 127 7 130 9 10 53 8 96 -4z m41 -424 c43 -20
66 -63 66 -120 0 -91 -45 -129 -150 -130 l-55 0 -3 133 -3 132 57 0 c31 0 70
-7 88 -15z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
